<template>
  <b-overlay
    id="overlay-background"
    :show="isLoading"
    :variant="`transparent`"
    :opacity="0.5"
    :blur="`10px`"
    rounded="sm"
    spinner-type="grow"
  >
    <section >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row >
            <b-col
              cols="12"
              xl="9"
              md="8"
            >
              <b-card
                no-body
                
              >
                <b-card-body>
                  <div class="row">
                    <div class="col-12">
                      <validation-provider
                        #default="validationContext"
                        name="title"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('common.general.title')"
                          label-for="title"
                        >
                          <b-form-input
                            id="title"
                            v-model="inputData.title"
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="$t('common.form.please_insert_data')"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <div class="col-12">
                      <validation-provider
                        #default="validationContext"
                        name="description"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('common.general.description')"
                          label-for="description"
                        >
                          <b-form-textarea
                            id="description"
                            v-model="inputData.description"
                            :state="
                              getValidationState(validationContext)
                            "
                            :placeholder="
                              $t('common.form.please_insert_data')
                            "
                            rows="5"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="4"
              xl="3"
              class="invoice-actions mt-md-0 mt-2"
            >
              <b-card>
                <b-form-checkbox
                  v-model="inputData.statusCode"
                  value="ACTIVE"
                  unchecked-value="INACTIVE"
                  class="custom-control-primary mb-75"
                  name="check-button"
                  switch
                  inline
                >{{ $t("common.form.publish") }}
                </b-form-checkbox>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  block
                  type="submit"
                >
                 {{ $t("common.form.save") }}
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </section>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  VBToggle, 
  BFormInvalidFeedback, 
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue';
import { required } from '@validations';
import Ripple from 'vue-ripple-directive';
import formValidation from '@core/comp-functions/forms/form-validation';
import faqServices from '@/services/faqServices';
import commonServices from '@/services/commonServices';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
export default {
  components: { 
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard, 
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea, 
    BFormInvalidFeedback, 
    BFormCheckbox,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  data() {
    return { 
      isLoading: false,
      id: this.$route.query.id,
      inputData: {},
      required, 
    };
  },
  watch: {
    data(value) {
      this.data = value;
      this.inputData = JSON.parse(JSON.stringify(this.data));
      return this.data;
    },
  },
  created() {
    if (this.id){
      this.getDetails()
    }
  },
  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      {},
    );
    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      if (!this.inputData.statusCode) {
        this.inputData.statusCode = 'INACTIVE';
      }
      await faqServices
        .createUpdate(this.inputData)
        .then((result) => {
          if (result) {
            if (result.errors) {
              commonServices.showError(this, 'msg.error.error');
            } else {
              commonServices.showSuccess(this, 'msg.success.success');
              this.$router.push({ name: 'list-faqs' });
            }
          }
        })
        .catch(() => {
          commonServices.showError(this, 'msg.error.error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 
    async getDetails() {
      this.isLoading = true;
      await faqServices
        .getDetail(this.id)
        .then((result) => {
          if (result) {
            this.inputData = result.data.getFAQ; 
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    
  },
};
</script>
